.options-container {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.options-container .option-item {
    border: 1px solid;
    border-radius: 50px;
    cursor: pointer;
    padding: 5px 10px;
    font-size: 12px;
    text-decoration: none;
    color: black;
}

.options-container .option-item:hover {
    background-color: #325464;
    color: #fff;

}