/* ======================== CONTAINER */
.react-chatbot-kit-chat-header {
    background-color: #325464;
    color: #fff;
}

/* ======================== CHATBOT MESSAGES */
.react-chatbot-kit-chat-bot-message {
    background-color: #E9F1F6;
    color: #325464;
}

.react-chatbot-kit-chat-bot-message-arrow {
    border-right-color: #E9F1F6;
}


/* ======================== USER MESSAGES */
.react-chatbot-kit-user-chat-message {
    background-color: #A9C3BF;
}

.react-chatbot-kit-user-chat-message-arrow {
    border-left-color: #A9C3BF;
}


/* ======================== FORM */
.react-chatbot-kit-chat-btn-send {
    background-color: #325464;
}

