.autoComplete {
    position: absolute;
    bottom: 41px;
    background-color: #325464;
    width: 100%;
}

.autoComplete ul {
    list-style-type: none;
    margin: 0px;
    padding: 10px 10px;
}

.autoComplete ul li {
    color: #fff;
}

.autoComplete ul li button {
    width: 100%;
}

.autoComplete ul li button:hover {
    cursor: pointer;
}

.autoComplete ul li:not(:first-child) {
    margin-top: 10px;
}